import { IconButton, InputAdornment, TextField } from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"

import PasswordComponent from "../PasswordComponent"
import React from "react"
import { useTranslation } from "react-i18next"

interface Props {
  formData: {
    firstName: string
    lastName: string
    email: string
    password: string
  }
  handleChange: (name: string, value: string | boolean) => void
  errors: {
    firstName?: string
    lastName?: string
    email?: string
    password?: string
  }
}

const RegistrationStep1: React.FC<Props> = ({ formData, handleChange, errors }) => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false)
  const { t } = useTranslation()

  return (
    <div>
      <TextField
        label={t("First Name")}
        variant="outlined"
        fullWidth
        value={formData.firstName}
        onChange={(e) => handleChange("firstName", e.target.value)}
        error={!!errors.firstName}
        helperText={errors.firstName}
        className="mb-4"
      />
      <TextField
        label={t("Last Name")}
        variant="outlined"
        fullWidth
        value={formData.lastName}
        onChange={(e) => handleChange("lastName", e.target.value)}
        error={!!errors.lastName}
        helperText={errors.lastName}
        className="mb-4"
      />
      <TextField
        label={t("email")}
        variant="outlined"
        fullWidth
        value={formData.email}
        onChange={(e) => handleChange("email", e.target.value)}
        error={!!errors.email}
        helperText={errors.email}
        className="mb-4"
      />
      <PasswordComponent
        value={formData.password}
        onChange={(e) => handleChange("password", e.target.value)}
        error={errors.password}
      />
      {/* <TextField
        label={t("Password")}
        type={showPassword ? "text" : "password"}
        variant="outlined"
        fullWidth
        value={formData.password}
        onChange={(e) => handleChange("password", e.target.value)}
        error={!!errors.password}
        helperText={errors.password}
        className="mt-4"
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          },
        }}
      /> */}
    </div>
  )
}

export default RegistrationStep1
