import { FormControlLabel, Switch, TextField } from "@mui/material"
import React, { useState } from "react"

import GoogleMapsAutoComplete from "../GoogleMapsAutoComplete"
import PhoneInput from "react-phone-input-2"
import { useTranslation } from "react-i18next"

interface Props {
  formData:
    | any
    | {
        phone: string
        address: string
        notifications: boolean
      }
  handleChange: (name: string, value: string | boolean) => void
  onAddressSelect?: any
  existingAddress?: any
  setIsAddressUnsaved?: any
}

const RegistrationStep2: React.FC<Props> = ({
  formData,
  handleChange,
  onAddressSelect,
  existingAddress,
  setIsAddressUnsaved,
}: any) => {
  const { t } = useTranslation()
  const [phoneNumber, setPhoneNumber] = useState(formData.phoneNumber || "+1")

  return (
    <div>
      {/* <TextField
        label={`${t("phone")} (${t("Optional")})`}
        variant="outlined"
        fullWidth
        value={formData.phone}
        onChange={(e) => handleChange("phoneNumber", e.target.value)}
        className="mb-4"
      /> */}
      <FormControlLabel
        label={`${t("phone")} (${t("Optional")})`}
        labelPlacement="top"
        sx={{
          width: "100%",
          alignItems: "start",
          margin: "15px 0",
        }}
        control={
          <PhoneInput
            country={formData.userAddress?.country || "us"}
            value={phoneNumber}
            onChange={(phone, data, e, formatted) => {
              handleChange("phoneNumber", formatted)
              //   setPhoneNumberFormatted(formatted)
              setPhoneNumber(phone)
            }}
            inputStyle={{ width: "100%", marginTop: "16px" }}
            containerStyle={{ width: "100%" }}
          />
        }
      />
      <GoogleMapsAutoComplete
        setIsAddressUnsaved={setIsAddressUnsaved}
        onAddressSelect={onAddressSelect}
        existingAddress={existingAddress}
      />

      <FormControlLabel
        control={
          <Switch
            checked={formData.pushNotifications}
            onChange={(e) => handleChange("pushNotifications", e.target.checked)}
          />
        }
        label={t("Receive Notifications")}
      />
    </div>
  )
}

export default RegistrationStep2
