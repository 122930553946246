import { Button, Typography } from "@mui/material"
import React, { useEffect } from "react"
import { auth, db } from "../firebase"
import { colors, typography } from "../theme"
import { doc, onSnapshot, query } from "firebase/firestore"
import { formatCash, formatNumber } from "../utils"

import LoginPage from "./Login"
import { PopupService } from "./PopupService"
import { observer } from "mobx-react-lite"
import { useAuthState } from "react-firebase-hooks/auth"
import { userStore } from "./stores/userStore"

export interface UserPointsProps {
  style?: any
  textStyle?: any
  isNoLink?: boolean
  textPosition?: "LEFT" | "RIGHT"
  size?: number
  iconColor?: string
  iconStyle?: any
  showMinDigits?: number
  precision?: number
  complete?: boolean
  variant?: "text" | "outlined" | "contained"
  className?: string
}

export const UserPoints = observer(function UserPoints(props: UserPointsProps) {
  const {
    style,
    isNoLink,
    size,
    textPosition,
    textStyle,
    iconColor,
    iconStyle,
    showMinDigits,
    precision,
    complete,
    variant = "contained",
    className,
  } = props

  const $styles = { ...$container, ...style }
  const $iconStyles = { ...$icon, ...iconStyle }
  const { points, isLoggedIn, setPoints } = userStore
  const [user] = useAuthState(auth)

  useEffect(() => {
    if (!isLoggedIn || !user) {
      return
    }
    const id: any = user.uid

    const querySnapshot = doc(db, "users", id)
    return onSnapshot(querySnapshot, {
      next: (snap) => {
        setPoints(snap?.data()?.points || 0)
      },
      error: (data) => {
        console.log("USERS-->", data)
      },
    })
  }, [isLoggedIn, user])

  if (!isLoggedIn) {
    return null
  }

  return (
    <Button
      variant={variant}
      style={$styles}
      className={`flex flex-row gap-1 p-1 ${className}`}
      startIcon={
        <img
          src={require("../assets/icons/FacilCoin2.png")}
          width={size || 20}
          style={$iconStyles}
          color={iconColor}
          alt="winfacil coin"
        />
      }
      // style={{ backgroundColor: colors.transparent }}
      onClick={() => {
        PopupService.setPopup(<LoginPage />)
      }}
    >
      <span className="truncate">
        {complete ? formatNumber(points) : formatCash(Math.floor(points), showMinDigits, precision)}
      </span>
    </Button>
  )
})

const $container: any = {
  justifyContent: "center",
  flexDirection: "row",
  marginRight: 10,
}

const $text: any = {
  fontFamily: typography.primary.normal,
  fontSize: 14,
  color: colors.palette.primary500,
  marginTop: 5,
}

const $icon: any = {
  marginLeft: 1,
}
