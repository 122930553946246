import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material"
import React, { useEffect, useState } from "react"
import { auth, db } from "../firebase"
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth"
import { doc, setDoc } from "firebase/firestore"

import GoogleMapsAutoComplete from "./GoogleMapsAutoComplete"
import { Logo } from "./Logo"
import { PopupService } from "./PopupService"
import RegistrationStep1 from "./RegistrationSteps/RegistrationStep1"
import RegistrationStep2 from "./RegistrationSteps/RegistrationStep2"
import RegistrationStep3 from "./RegistrationSteps/RegistrationStep3"
import { detectCurrentCountry } from "../CountrySelector"
import { getFriendlyFirebaseError } from "./firebaseErrorHandler"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"

interface FormData {
  country: string
  firstName: string
  lastName: string
  email: string
  password: string
  phoneNumber: string
  language: string
  pushNotifications: boolean
  name: string
  displayName: string
  userAddress: UserAddress
  acceptedTerms: boolean
}

interface UserAddress {
  city: string
  country: string
  county: string
  houseNumber: number | null
  postcode: string
  province: string
  street: string
}

interface Errors {
  acceptedTerms?: string
  firstName?: string
  lastName?: string
  email?: string
  password?: string
}

const steps = ["Account Details", "Additional Information", "Review & Submit"]

const Register: React.FC = () => {
  const [activeStep, setActiveStep] = useState<number>(0)
  const [formData, setFormData] = useState<FormData>({
    country: "US",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
    language: "English",
    pushNotifications: true,
    name: "",
    displayName: "",
    userAddress: {
      city: "",
      country: "",
      county: "",
      houseNumber: null,
      postcode: "",
      province: "",
      street: "",
    },
    acceptedTerms: false,
  })
  const [errors, setErrors] = useState<Errors>({})
  const [firebaseError, setFirebaseError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isAddressUnsaved, setIsAddressUnsaved] = useState<boolean>(false)
  const [showAddressWarning, setShowAddressWarning] = useState<boolean>(false)
  const [emailVerificationSent, setEmailVerificationSent] = useState<boolean>(false)

  const { t } = useTranslation()

  useEffect(() => {
    detectCurrentCountry((country: string) => {
      setFormData((d) => ({
        ...d,
        country,
        selectedLocation: { country },
        userAddress: { ...d.userAddress, country },
      }))
    })
  }, [])

  const handleNext = () => {
    if (isAddressUnsaved) {
      setShowAddressWarning(true) // Show warning if address is unsaved
    } else if (validateStep(activeStep)) {
      setActiveStep((prevStep) => prevStep + 1)
    }
  }

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1)
    }
  }

  const handleChange = (name: any, value: string | boolean | UserAddress) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
      ...(name === "firstName" || name === "lastName"
        ? {
            name: `${prev.firstName} ${prev.lastName}`,
            displayName: `${prev.firstName} ${prev.lastName}`,
          }
        : {}),
    }))
    setErrors((prev) => ({ ...prev, [name]: "" }))
    setFirebaseError(null)
  }

  const handleAddressSelect = (address: UserAddress, formattedAddress: string) => {
    setFormData((prev) => ({
      ...prev,
      country: address.country,
      userAddress: address,
    }))
    // console.log("Formatted Address:", formattedAddress)
  }

  const validateStep = (step: number): boolean => {
    const newErrors: Errors = {}

    if (step === 0) {
      if (!formData.firstName) newErrors.firstName = t("First name is required")
      if (!formData.lastName) newErrors.lastName = t("Last name is required")
      if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email))
        newErrors.email = t("Valid email is required")
      if (!formData.password || formData.password.length < 6)
        newErrors.password = "Password must be at least 6 characters"
    } else if (step === 2) {
      if (!formData.acceptedTerms) newErrors.acceptedTerms = t("mustAcceptTerms")
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const handleSubmit = async () => {
    if (validateStep(activeStep)) {
      setIsLoading(true)
      setFirebaseError(null)

      try {
        // Create user with Firebase Authentication
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          formData.email,
          formData.password,
        )
        const userId = userCredential.user.uid

        // Update Firebase Auth display name
        await updateProfile(auth.currentUser!, {
          displayName: formData.displayName,
        })

        // Send email verification
        await sendEmailVerification(auth.currentUser!)
        setEmailVerificationSent(true)

        // Create user profile in Firestore
        await setDoc(doc(db, "users", userId), {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phoneNumber: formData.phoneNumber || null,
          language: formData.language,
          pushNotifications: formData.pushNotifications,
          name: formData.name,
          displayName: formData.displayName,
          userAddress: formData.userAddress,
          acceptedTerms: formData.acceptedTerms,
          country: formData.country,
          uid: userId,
        })

        // console.log("User created successfully:", formData)
        setActiveStep(steps.length) // Proceed to the confirmation page
        PopupService.setPopup(null)
      } catch (error: any) {
        const friendlyMessage = getFriendlyFirebaseError(error.message, t)
        setFirebaseError(friendlyMessage)
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <Container maxWidth="md" className="p-6">
      <Logo
        className="flex w-full justify-center"
        style={{ alignSelf: "center", marginBottom: 20 }}
      />
      <Stepper activeStep={activeStep} alternativeLabel className="mb-8">
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{t(label)}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {firebaseError && (
        <Alert severity="error" className="mb-4">
          {firebaseError}
        </Alert>
      )}

      {emailVerificationSent && (
        <Alert severity="info" className="mb-4">
          {t("emailVerificationSent").replace("$1", formData.email)}
        </Alert>
      )}

      <motion.div
        key={activeStep}
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -100 }}
        transition={{ duration: 0.5 }}
      >
        {activeStep === 0 && (
          <RegistrationStep1 formData={formData} handleChange={handleChange} errors={errors} />
        )}
        {activeStep === 1 && (
          <RegistrationStep2
            formData={formData}
            handleChange={handleChange}
            onAddressSelect={handleAddressSelect}
            existingAddress={formData.userAddress}
            setIsAddressUnsaved={setIsAddressUnsaved}
          />
        )}
        {activeStep === 2 && <RegistrationStep3 formData={formData} />}
      </motion.div>

      {activeStep === steps.length - 1 ? (
        <div>
          {!!errors.acceptedTerms && (
            <FormLabel error={true} color="error">
              {errors.acceptedTerms}
            </FormLabel>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.acceptedTerms}
                onChange={(e) => handleChange("acceptedTerms", e.target.checked)}
              />
            }
            label={t`I accept the terms and conditions`}
          />
        </div>
      ) : null}

      <Box className="flex justify-between mt-8">
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          variant="contained"
          color="secondary"
        >
          {t`back`}
        </Button>

        {activeStep === steps.length - 1 ? (
          <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? t("Submitting...") : t("Submit")}
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={handleNext}>
            {t`next`}
          </Button>
        )}
      </Box>

      {activeStep === steps.length - 1 ? (
        <Box display="flex" justifyContent="center" width="100%" mt={2}>
          <Button
            color="primary"
            onClick={() => {
              PopupService.pushPopup(
                <iframe style={{ width: "100%", height: "100%" }} src="/privacy.html" />,
                null,
                { width: "100%", height: "100%" },
              )
            }}
          >
            {t`Terms and Conditions`}
          </Button>
        </Box>
      ) : null}

      <Dialog open={showAddressWarning} onClose={() => setShowAddressWarning(false)}>
        <DialogTitle>{t("Unsaved Address Changes")}</DialogTitle>
        <DialogContent>
          <Alert severity="warning">
            {t`You have unsaved changes to the address. Please submit or continue without saving.`}
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddressWarning(false)} color="warning">
            {t`Cancel`}
          </Button>
          <Button
            onClick={() => {
              setShowAddressWarning(false)
              setActiveStep((prevStep) => prevStep + 1) // Proceed to the next step
            }}
            color="primary"
          >
            {t`Continue Without Saving`}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default Register
