import React from "react"
import { useTranslation } from "react-i18next"

interface Props {
  formData: {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    userAddress: {
      city: string
      country: string
      county: string
      houseNumber: number | null
      postcode: string
      province: string
      street: string
    }
  }
}

const RegistrationStep3: React.FC<Props> = ({ formData }) => {
  const { t } = useTranslation()

  return (
    <div className="p-4">
      <h2 className="text-lg font-bold mb-4">Review Your Information</h2>
      <p>
        <strong>{t`First Name`}:</strong> {formData.firstName}
      </p>
      <p>
        <strong>{t`Last Name`}:</strong> {formData.lastName}
      </p>
      <p>
        <strong>{t`Email`}:</strong> {formData.email}
      </p>
      <p>
        <strong>{t`Phone`}:</strong> {formData.phoneNumber}
      </p>
      <h3 className="text-lg font-bold mt-4">{t`Address`}</h3>
      <p>
        <strong>{t`Street`}:</strong> {formData.userAddress.street}
      </p>
      <p>
        <strong>{t`House Number`}:</strong> {formData.userAddress.houseNumber}
      </p>
      <p>
        <strong>{t`City`}:</strong> {formData.userAddress.city}
      </p>
      <p>
        <strong>{t`Province`}:</strong> {formData.userAddress.province}
      </p>
      <p>
        <strong>{t`Country`}:</strong> {formData.userAddress.country}
      </p>
      <p>
        <strong>{t`Postcode`}:</strong> {formData.userAddress.postcode}
      </p>
      <p>
        <strong>{t`County`}:</strong> {formData.userAddress.county}
      </p>
    </div>
  )
}

export default RegistrationStep3
