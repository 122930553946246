import { Button, CircularProgress, IconButton } from "@mui/material"
import React, { useEffect, useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LocationService } from "./LocationService"
import { faLocationCrosshairs } from "@fortawesome/free-solid-svg-icons"
import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"

const CountryCityStateFetcher: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<{ country: string; city: string; state: string } | null>(null)
  const { t } = useTranslation()
  const [showButton, setShowButton] = useState(true)

  // Use the Intl API as an alternative for region information
  useEffect(() => {
    try {
      if (!navigator.geolocation) {
        const userRegion = (Intl as any).Locale ? new Intl.Locale(navigator.language).region : null
        if (userRegion) {
          setData({ country: userRegion, city: "", state: "" })
        } else {
          setShowButton(false)
        }
      }
    } catch (error: any) {
      setShowButton(false)
    }
  }, [])

  const fetchLocationData = async (latitude: number, longitude: number) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_GEO_API_KEY
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`,
      )

      const responseData = await response.json()

      if (responseData.status === "OK") {
        const addressComponents = responseData.results[0].address_components
        let country = ""
        let state = ""
        let city = ""

        addressComponents.forEach((component: any) => {
          const types = component.types
          if (types.includes("country")) {
            country = component.short_name
          } else if (types.includes("administrative_area_level_1")) {
            state = component.long_name
          } else if (types.includes("locality")) {
            city = component.long_name
          }
        })

        setData({ country, city, state })

        const locationObj = {
          country: country,
          province: state,
          city: city,
        }
        LocationService.setLocation(locationObj)

        // Optionally, save the data to Firestore
        // await addDoc(collection(db, 'locations'), { country, city, state });
      } else {
        console.error("Failed to retrieve location data:", responseData.status)
      }
    } catch (error) {
      console.error("Error fetching location data:", error)
    }
  }

  const handleGetLocation = () => {
    setLoading(true)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords
          fetchLocationData(latitude, longitude)
          setLoading(false)
        },
        (error) => {
          console.error("Error getting geolocation:", error)
          setLoading(false)
          setShowButton(false)
        },
      )
    } else {
      console.error("Geolocation is not supported by this browser.")
      setLoading(false)
      setShowButton(false)
    }
  }

  if (!showButton) {
    return null
  }

  return (
    <>
      <IconButton
        size="small"
        color="primary"
        className="bg-blue-500 hover:bg-blue-700 text-white rounded"
        onClick={handleGetLocation}
      >
        {loading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <FontAwesomeIcon icon={faLocationCrosshairs} />
        )}
      </IconButton>
      {/* <Button
        variant="contained"
        color="primary"
        onClick={handleGetLocation}
        disabled={loading}
        startIcon={<FontAwesomeIcon icon={faLocationCrosshairs} />}
        className="bg-blue-500 hover:bg-blue-700 text-white rounded"
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : null}
        {t("Get Current Location")}
      </Button> */}

      {process.env.NODE_ENV === "development" && data && (
        <motion.div
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="mt-4 p-4 border border-gray-300 rounded shadow-sm w-64 text-center fixed"
        >
          <p className="text-lg font-semibold">
            {t("Country")}: {data.country}
          </p>
          <p className="text-lg font-semibold">
            {t("City")}: {data.city}
          </p>
          <p className="text-lg font-semibold">
            {t("State")}: {data.state}
          </p>
        </motion.div>
      )}
    </>
  )
}

export default CountryCityStateFetcher
