import { Button, Icon } from "@mui/material"

import LoginPage from "./Login"
import { PopupService } from "./PopupService"
import React from "react"
import Tickets from "./Tickets"
import { auth } from "../firebase"
import { formatCash } from "../utils"
import { observer } from "mobx-react-lite"
import { toJS } from "mobx"
import { useAuthState } from "react-firebase-hooks/auth"
import { userStore } from "./stores/userStore"

export interface UsersCounterProps {
  style?: any
}

export const UsersTickets = observer(function UsersCounter(props: UsersCounterProps) {
  const { isLoggedIn } = userStore

  const { style } = props
  const $styles = { ...$container, ...style }
  const { ticketsCount } = userStore
  const iconDefault: any = {
    name: "book_online",
    isFont: true,
    isFa5: true,
    style: { marginLeft: 1 },
    size: "medium",
    isUrl: false,
  }

  const [user] = useAuthState(auth)

  if (!isLoggedIn) {
    return null
  }

  return (
    <Button
      variant="contained"
      style={$styles}
      className="flex flex-row gap-1/2 p-1"
      startIcon={
        <Icon fontSize={iconDefault.size} style={toJS(iconDefault.style)}>
          {iconDefault.name}
        </Icon>
      }
      onClick={() => {
        user && !user?.isAnonymous && user.emailVerified
          ? PopupService.setPopup(<Tickets />, "", {
              maxWidth: "100%",
              width: "100%",
              height: "100%",
            })
          : PopupService.setPopup(<LoginPage />)
      }}
    >
      {formatCash(ticketsCount)}
    </Button>
  )
})

const $container: any = {
  justifyContent: "center",
  flexDirection: "row",
}
