import { Button, Typography } from "@mui/material"

import React from "react"
import { getLocationStr } from "../utils"
import { observer } from "mobx-react-lite"
import { useLocation } from "./LocationService"
import { useTranslation } from "react-i18next"

interface UserLocationButtonProps {
  style?: React.CSSProperties
  textPosition?: string
  iconColor?: string
  iconStyle?: React.CSSProperties
  hideIcon?: boolean
  color?: any
  fullWidth?: boolean
  onPress?: (location: any) => void
}

export const UserLocationButton = observer(function (props: UserLocationButtonProps) {
  const { textPosition, iconColor, iconStyle, hideIcon, color, fullWidth, onPress } = props

  const { location } = useLocation()

  const { t } = useTranslation()

  const UserLocationButtonText = () => {
    const loc = { ...location }
    loc.country = t((loc.country || "us").toLowerCase())
    return <Typography fontSize="smaller">{getLocationStr(loc)}</Typography>
  }

  return (
    <Button
      variant="contained"
      color={color || "success"}
      fullWidth={fullWidth}
      onClick={() => onPress && onPress(location)}
    >
      {textPosition !== "RIGHT" && UserLocationButtonText()}
      {!hideIcon && <LocationIcon style={iconStyle} color={iconColor} size={20} />}
      {textPosition === "RIGHT" && UserLocationButtonText()}
    </Button>
  )
})

const LocationIcon: React.FC<{ size?: number; style?: React.CSSProperties; color?: string }> = ({
  size = 20,
  style,
  color,
}) => null // Add actual icon implementation if needed
