import { MuiThemeProvider, StylesProvider, createGenerateClassName } from "@material-ui/core/styles"

import React from "react"
import { ThemeProvider } from "@mui/material/styles"
import { observer } from "mobx-react-lite"
import { userStore } from "./components/stores/userStore"

const generateClassName = createGenerateClassName({
  seed: "classes-custom-prefix",
})

export const Provider = observer((props: any) => {
  const { children } = props
  const { theme } = userStore

  return (
    <StylesProvider generateClassName={generateClassName}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  )
})
