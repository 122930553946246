import React, { useEffect } from "react"
import { messaging, onMessage } from "./firebase"

const NotificationComponent: React.FC = () => {
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log("Received foreground message:", payload)
      alert(`Notification: ${payload.notification?.title}`)
    })

    return () => unsubscribe()
  }, [])

  return <div></div>
}

export default NotificationComponent
