import { IconButton, InputAdornment, TextField } from "@mui/material"
import React, { useState } from "react"
import { Visibility, VisibilityOff } from "@mui/icons-material"

import { useTranslation } from "react-i18next"

const PasswordComponent = ({
  value,
  error,
  onChange,
}: {
  value: string
  error?: string
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const { t } = useTranslation()

  return (
    <TextField
      label={t("Password")}
      type={showPassword ? "text" : "password"}
      variant="outlined"
      fullWidth
      value={value}
      onChange={onChange}
      error={!!error}
      helperText={error}
      className="mt-4"
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
    />
  )
}

export default PasswordComponent
