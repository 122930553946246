import {
  BrowserRouter,
  Link,
  Navigate,
  Outlet,
  Route,
  Routes as RoutesBase,
  redirect,
} from "react-router-dom"

import App from "./App"
import { AuthProvider } from "./AuthProvider"
import { BusinessAds } from "./components/BusinessAds"
// import Countries from './components/Countries';
import Home from "./components/Home"
import Login from "./components/Login"
// import LoginsScreen from './components/LoginScreen';
// import Notification from './components/Notification';
// import Onboarding from './components/Onboarding';
import React from "react"
import ScrollToAnchor from "./ScrollToAnchor"
// import ReceiptContentExtractor from './components/ReceiptContentExtractor';
import Tickets from "./components/Tickets"

// import {
//   Home as HomeIcon,
//   LanguageRounded,
//   LiveHelpRounded,
//   LocationSearchingOutlined,
//   Login as LoginIcon,
//   MonetizationOn,
//   SettingsApplicationsRounded,
//   SupervisedUserCircle as UsersIcon
// } from '@mui/icons-material';

// import Translations from './components/Translations';
// import Users from './components/Users';

// const routes = [
//   { name: '', title: 'Home', icon: <HomeIcon />, element: <Home />, auth: false },
//   { name: 'login', title: 'Login', icon: <LoginIcon />, element: <Login />, auth: false },
//   { name: 'users', title: 'Users', icon: <UsersIcon />, element: <Users />, auth: true },
//   { name: 'tickets', title: 'Tickets', icon: <MonetizationOn />, element: <Tickets />, auth: true },
//   { name: 'countries', title: 'Countries', icon: <LocationSearchingOutlined />, element: <Countries />, auth: true },
//   { name: 'translations', title: 'Translations', icon: <LanguageRounded />, element: <Translations />, auth: true },
//   { name: 'onboarding', title: 'Onboarding', icon: <LiveHelpRounded />, element: <Onboarding />, auth: true },
//   { name: 'notifications', title: 'Notifications', icon: <SettingsApplicationsRounded />, element: <Notification />, auth: true },
//   { name: 'login-screen-config', title: 'Login Screen', icon: <SettingsApplicationsRounded />, element: <LoginsScreen />, auth: true },
//   { name: 'receipt-content-extractor', title: 'Receipt Content Extractor', icon: <SettingsApplicationsRounded />, element: <ReceiptContentExtractor />, auth: true }
// ];

export const Routes = () => {
  return (
    <BrowserRouter>
      <RoutesBase>
        <Route path="" element={<App />}>
          <Route path="" element={<Home />} />

          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="login" element={<Login />} />
          <Route path="tickets" element={<Tickets />} />
        </Route>
        <Route path="ads/:i" element={<BusinessAds />} />
      </RoutesBase>
      <ScrollToAnchor />
    </BrowserRouter>
  )
}
