import { Alert, Dialog, DialogContent, DialogTitle, IconButton, styled } from "@mui/material"
import { LocationService, useLocation } from "./LocationService"
import React, { useEffect, useMemo, useState } from "react"

import { Close as CloseIcon } from "@mui/icons-material"
import LocationSelector from "./LocationSelector"
import { UserLocationButton } from "./UserLocationButton"
import { detectCurrentCountry } from "../CountrySelector"
import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import { userStore } from "./stores/userStore"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "130vw",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
}))

const rootStyle: any = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "baseline",
}

let setFromStore = false

interface UserLocationProps {
  style?: React.CSSProperties
  textPosition?: string
  iconColor?: string
  iconStyle?: React.CSSProperties
  hideIcon?: boolean
  viewOnly?: boolean
  color?: any
  fullWidth?: boolean
}

const initialFilter = { country: "", province: "", city: "" }

export const UserLocation = observer(function (props: UserLocationProps) {
  const { viewOnly } = props

  const [modalVisible, setModalVisible] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<string>("")
  const [selectedProvince, setSelectedProvince] = useState<string>("")
  const [selectedCity, setSelectedCity] = useState<string>("")
  const [userSelectedFilter, setUserSelectedFilter] = useState(initialFilter)
  const [error, setError] = useState<string | null>(null)

  const { availableLocations } = useLocation()
  const { t } = useTranslation()
  const { user: userFromStore } = userStore

  const { location } = useLocation()

  const validateLocation = (locationObj: typeof initialFilter) => {
    if (!locationObj.country) {
      setError(t("Please select a valid country."))
      return false
    }
    if (locationObj.country && !availableLocations[locationObj.country]) {
      setError(t("Selected country is not available."))
      return false
    }
    if (locationObj.province && !availableLocations[locationObj.country]?.[locationObj.province]) {
      setError(t("Selected province is not available in the chosen country."))
      return false
    }
    if (
      locationObj.city &&
      !availableLocations[locationObj.country]?.[locationObj.province]?.includes(locationObj.city)
    ) {
      setError(t("Selected city is not available in the chosen province."))
      return false
    }
    setError(null)
    return true
  }

  useEffect(() => {
    detectCurrentCountry((code: string) => {
      if (!selectedCountry && !userFromStore?.userAddress?.country) {
        setSelectedCountry(code)
      }
    })
    setUserSelectedFilter({ ...initialFilter, country: "US" })
  }, [selectedCountry, userFromStore?.userAddress?.country])

  useEffect(() => {
    if (!setFromStore && userFromStore) {
      setFromStore = true
      const { country, province, city } = userFromStore.userAddress || {}
      const locationObj = {
        country: country || "US",
        province: province || "",
        city: city || "",
      }
      if (validateLocation(locationObj)) {
        setSelectedCountry(locationObj.country)
        setSelectedProvince(locationObj.province)
        setSelectedCity(locationObj.city)
        setUserSelectedFilter(locationObj)
      }
    }
  }, [userFromStore])

  useMemo(() => {
    if (userSelectedFilter) {
      setSelectedCountry(userSelectedFilter.country)
      setSelectedProvince(userSelectedFilter.province)
      setSelectedCity(userSelectedFilter.city)
    }
  }, [userSelectedFilter])

  useEffect(() => {
    const locationObj = {
      country: selectedCountry,
      province: selectedProvince,
      city: selectedCity,
    }
    if (validateLocation(locationObj)) {
      LocationService.setLocation(locationObj)
      setUserSelectedFilter(locationObj)
    }
  }, [selectedCountry, selectedProvince, selectedCity])

  useEffect(() => {
    if (validateLocation(location)) {
      setUserSelectedFilter(location)
    }
  }, [location])

  const handleChange = (name: string, value: string) => {
    let locationObj = { ...userSelectedFilter }

    switch (name) {
      case "country":
        setSelectedCountry(value)
        locationObj = { ...locationObj, country: value, province: "", city: "" }
        break
      case "state":
        setSelectedProvince(value)
        locationObj = { ...locationObj, province: value, city: "" }
        break
      case "city":
        setSelectedCity(value)
        locationObj = { ...locationObj, city: value }
        break
      default:
        return
    }

    if (validateLocation(locationObj)) {
      setUserSelectedFilter(locationObj)
    }
  }

  if (viewOnly) return <UserLocationButton />

  return (
    <div>
      {error && <Alert severity="error">{error}</Alert>}
      <BootstrapDialog open={modalVisible}>
        <div style={rootStyle}>
          <DialogTitle sx={{ m: 0, p: 2 }}>{t`Select Location`}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setModalVisible(false)}
            sx={{ position: "absolute", right: 8, top: 8, color: "grey.500" }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent dividers sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <LocationSelector
            userSelectedCountry={selectedCountry}
            userSelectedState={selectedProvince}
            userSelectedCity={selectedCity}
            onChange={handleChange}
          />
        </DialogContent>
      </BootstrapDialog>
      <UserLocationButton onPress={() => setModalVisible(true)} />
    </div>
  )
})