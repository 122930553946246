import { Button, Typography } from "@mui/material"
import { LazyLoadImage, trackWindowScroll } from "react-lazy-load-image-component"
import React, { useEffect, useState } from "react"

import Carousel from "react-material-ui-carousel"
import { ISponsor } from "./stores/Sponsor"
import { ISponsorOffers } from "./stores/SponsorOffers"
import { PopupService } from "./PopupService"
import { SponsorOfferImage } from "./stores/SponsorOfferImage"
import SponsorServices from "./SponsorServices"
import { colors } from "../theme"
import { matchTwoLocations } from "../utils"
import { observer } from "mobx-react-lite"
import { toJS } from "mobx"
import { useLocation } from "./LocationService"
import { useTranslation } from "react-i18next"
import { userStore } from "./stores/userStore"

export interface SponsorsProps {
  style?: any
  scrollPosition?: any
}

export const Sponsors = trackWindowScroll(
  observer(function Sponsors(props: SponsorsProps) {
    const { scrollPosition } = props
    const { t } = useTranslation()

    const { location } = useLocation()

    const { sponsors, displayContentConfig, listenForSponsorsChanges } =
      userStore

    useEffect(() => {
      return listenForSponsorsChanges(location)
    }, [listenForSponsorsChanges, location])

    return (
      <div style={$container} id="sponsors-content">
        <Typography variant="h6" fontSize="smaller">
          {t(
            (
              displayContentConfig?.sponsorsTitleKey ||
              displayContentConfig?.sponsorsTitle ||
              ""
            ).trim(),
          )}
        </Typography>
        <div className="flex flex-row justify-center flex-wrap gap-1">
          {toJS(sponsors)?.map?.((item, i) => {
            const key = `${item.id}-${i}`

            return (
              <Button
                variant="outlined"
                className="p-1"
                key={key}
                onClick={() => {
                  PopupService.setPopup(<Sponsor sponsor={item} />, "", {
                    maxWidth: "100%",
                    width: "100%",
                    height: "100%",
                  })
                }}
              >
                <LazyLoadImage
                  alt={`sponsor-${item.id}-${i}`}
                  scrollPosition={scrollPosition}
                  src={item.iconUrl}
                  width={90}
                />
              </Button>
            )
          })}
        </div>
      </div>
    )
  }),
)

const $container: any = {
  borderTopColor: colors.border,
  borderTopWidth: 5,
  paddingTop: 5,
}

export default function Sponsor(props: { sponsor: ISponsor }) {
  const { sponsor } = props
  const { getThisMonthOffers } = userStore
  const [offers, setOffers] = useState<ISponsorOffers[]>([])

  useEffect(() => {
    async function getOffers() {
      const result = await getThisMonthOffers(sponsor.id)
      setOffers(result)
    }
    if (sponsor) {
      getOffers()
    }
  }, [sponsor])

  if (!sponsor) {
    return null
  }

  return (
    <>
      <SponsorOffers offers={offers} />
      <SponsorServices sponsorId={sponsor.id} />
    </>
  )
}

const SponsorOffers = ({ offers }: any) => {
  const { t } = useTranslation()

  return (
    <>
      <Typography variant="h5" className="font-bold mb-4">
        {t("Today's Offers")}
      </Typography>
      {!offers?.length ? (
        <Typography variant="h6" className="text-center">
          {t("noOffersAvailable")}
        </Typography>
      ) : (
        offers.map((offer: ISponsorOffers, i: number) => (
          <div
            key={`${offer.title}-${i}`}
            style={{
              borderWidth: 1,
              borderColor: "#DDD",
              borderStyle: "solid",
              marginTop: 10,
              marginBottom: 10,
              padding: 10,
            }}
          >
            <Typography variant="h4">{offer.title}</Typography>
            {/* <Typography variant="h5">{offer.subtitle}</Typography> */}
            <Typography variant="h6">{offer.description}</Typography>
            <Carousel
              cycleNavigation
              fullHeightHover
              indicators
              navButtonsAlwaysVisible
              // height={400}
              autoPlay
              animation="slide"
              // duration={500}
              swipe
              interval={4000}
              stopAutoPlayOnHover
            >
              {offer.images.map((item: SponsorOfferImage, i: number) => (
                <Button
                  variant="text"
                  href={item.link}
                  key={i}
                  className="flex justify-center w-full"
                >
                  <img
                    src={item.image}
                    style={{
                      height: "50vh",
                      width: "90%",
                      objectFit: "contain",
                    }}
                  />
                </Button>
              ))}
            </Carousel>
            {/* <Typography>{new Date(offer.raffleDate).toString()}</Typography> */}
            {/* <div>
        <Button
          startIcon={<Image src={require("../../assets/icons/termsIcon.png")} width={30} />}
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            // PopupService.pushPopup(raffle.termsText)
            PopupService.pushPopup(htmr(offer.termsText, { transform }))
          }}
          sx={{ mt: 2 }}
        >
          {t("terms")}
        </Button>
      </div> */}
          </div>
        ))
      )}
    </>
  )
}
