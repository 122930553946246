import "react-phone-input-2/lib/style.css"

import { AppProvider, SignInPage } from "@toolpad/core"
import { Box, Button, Container, TextField, Typography } from "@mui/material"
import {
  GoogleAuthProvider,
  MultiFactorError,
  OAuthProvider,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  getMultiFactorResolver,
  sendEmailVerification,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  signInWithPopup,
} from "firebase/auth"
import React, { useEffect, useState } from "react"
import { auth, googleProvider } from "../firebase"

import ForgotPasswordPage from "./ForgotPasswordPage"
import { LoadingService } from "../LoadingService"
import { LocationService } from "./LocationService"
import { Logo } from "./Logo"
import PasswordComponent from "./PasswordComponent"
import PhoneInput from "react-phone-input-2"
import { PopupService } from "./PopupService"
import Profile from "./Profile"
import RegistrationPage from "./Register"
import { getData } from "./core/CreateEditOptions/getData"
import { useAuthState } from "react-firebase-hooks/auth"
import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"

const providers = [
  { id: "apple", name: "Apple" },
  { id: "google", name: "Google" },
  { id: "credentials", name: "Credentials" },
  // { id: "facebook", name: "Facebook" },
  // { id: "twitter", name: "Twitter" },
  // { id: "linkedin", name: "LinkedIn" },
]

const LoginPage = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [otp, setOtp] = useState("")
  const [confirmationResult, setConfirmationResult] = useState(null)
  const [mfaVerificationId, setMfaVerificationId] = useState(null)
  const [errors, setErrors] = useState({ email: "", password: "", phoneNumber: "" })
  const [user, loading, error] = useAuthState(auth)
  // const navigate = useNavigate();
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [mfaError, setMfaError] = useState(null)
  const { t } = useTranslation()
  const theme = useTheme()
  const [isEmailVerified, setIsEmailVerified] = useState(null)
  const [showResendVerification, setShowResendVerification] = useState(false)

  // useEffect(() => {
  //     initFirebaseUI();
  // }, []);

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return
    }

    if (user && !user?.isAnonymous) {
      getData({
        setData: (data) => {
          console.log("getData USER  DATA: ", data)
          setSelectedRowData(data)
          data?.userAddress && LocationService.setLocation(data.userAddress)
        },
        path: `users/${user.uid}`,
      })
      // navigate('/app-config');
    }
  }, [user, loading])

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const handleLogin = async (formData) => {
    let e = email
    let p = password

    if (formData) {
      e = formData.get("email") || email
      p = formData.get("password") || password
    }

    if (!validateEmail(e)) {
      setErrors((prev) => ({ ...prev, email: t("Invalid email format") }))
      return
    }
    if (!p) {
      setErrors((prev) => ({ ...prev, password: t("passwordIsRequired") }))
      return
    }

    LoadingService.setLoading(true)
    try {
      const userCredential = await signInWithEmailAndPassword(auth, e, p)

      // Check if email is verified
      if (!user.emailVerified) {
        setIsEmailVerified(false)
        setShowResendVerification(true)
      } else {
        setIsEmailVerified(true)
      }

      console.log("userCredential---->", userCredential)
      PopupService.setPopup(null)
      // Handle successful login
    } catch (error) {
      if (error.code === "auth/multi-factor-auth-required") {
        setMfaError(error)
        const resolver = getMultiFactorResolver(auth, error)
        handleMfaSignIn(resolver)
      } else if (error.code === "auth/invalid-credential") {
        PopupService.pushPopup(t("errorTryingToLogin"), "Error")
      } else if (error.code === "auth/too-many-requests") {
        PopupService.pushPopup(t("Access to this account has been temporarily disabled"), "Error")
      } else {
        const errorTitle = t("Error signing in with password")
        const message = error?.message
        const hasMessage = !!error?.message

        const messageTrimmed = hasMessage ? message.substring(message.indexOf("Firebase: ")) : ""

        PopupService.pushPopup(messageTrimmed || errorTitle, !hasMessage ? "Error" : errorTitle)
        console.error(errorTitle, error)
      }
    } finally {
      LoadingService.setLoading(false)
    }
  }

  const handleMfaSignIn = async (resolver) => {
    const phoneAuthProvider = new PhoneAuthProvider(auth)
    const appVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
      },
    })
    LoadingService.setLoading(true)

    try {
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        },
        appVerifier,
      )
      setMfaVerificationId(verificationId)
    } catch (error) {
      console.error("Error during MFA sign-in", error)
    } finally {
      LoadingService.setLoading(false)
    }
  }

  const handleConfirmMfaOtp = async () => {
    if (!mfaVerificationId) {
      return
    }

    // const credential = PhoneAuthProvider.credential(mfaVerificationId, otp);
    const mfaAssertion = getMultiFactorResolver(auth, mfaError)

    // LoadingService.setLoading(true)

    try {
      // const multiFactorAssertion =
      // TotpMultiFactorGenerator.assertionForSignIn(
      //     mfaResolver.hints.find(x => x === PhoneMultiFactorGenerator.FACTOR_ID).uid,
      //     otp
      // );
      // const recaptchaVerifier = new RecaptchaVerifier(
      //     'recaptcha-container',
      //     {
      //       size: 'invisible',
      //     },
      //     this.auth
      //   )
      //   const phoneInfoOptions = {
      //     multiFactorHint: mfa,
      //     session: this.resolver!.session,
      //   };
      // const provider = new PhoneAuthProvider(auth);
      // const verificationId = await provider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);

      const authCredential = PhoneAuthProvider.credential(mfaVerificationId, otp)
      const userCredential = await signInWithCredential(auth, authCredential)

      const phoneAuthCredential = PhoneAuthProvider.credential(mfaVerificationId, otp)

      await mfaAssertion.resolveSignIn(phoneAuthCredential)

      PopupService.setPopup(null)
      // Handle successful MFA sign-in
    } catch (error) {
      console.error("Error verifying MFA OTP", error)
    } finally {
      LoadingService.setLoading(false)
    }
  }

  const handleGoogleLogin = async () => {
    // const provider = new GoogleAuthProvider()
    // LoadingService.setLoading(true)

    // try {
    //   await signInWithPopup(auth, provider)
    //   // Handle successful login
    // } catch (error) {
    //   console.error("Error signing in with Google", error)
    // } finally {
    //   LoadingService.setLoading(false)
    // }
    try {
      await signInWithPopup(auth, googleProvider)
      // console.log("Google user signed in: ", result.user)
      PopupService.setPopup(null)
    } catch (error) {
      console.error("Google sign-in error: ", error)
    }
  }

  const handleAppleLogin = async () => {
    const provider = new OAuthProvider("apple.com")
    LoadingService.setLoading(true)
    try {
      await signInWithPopup(auth, provider)
      PopupService.setPopup(null)
      // Handle successful login
    } catch (error) {
      console.error("Error signing in with Apple", error)
    } finally {
      LoadingService.setLoading(false)
    }
  }

  const handlePhoneSignIn = async () => {
    if (!phoneNumber) {
      setErrors((prev) => ({ ...prev, phoneNumber: t("Phone number is required") }))
      return
    }

    // Initialize RecaptchaVerifier
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
        size: "invisible", //"normal", // or 'invisible' based on your needs
        callback: (response) => {
          // reCAPTCHA solved - allow the user to send the OTP
          console.log("reCAPTCHA solved", response)
          LoadingService.setLoading(false)
        },
        "expired-callback": () => {
          // Handle expiration
          console.log("reCAPTCHA expired")
          LoadingService.setLoading(false)
        },
      })
    }

    const appVerifier = window.recaptchaVerifier

    // LoadingService.setLoading(true)
    try {
      const result = await signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      setConfirmationResult(result)
    } catch (error) {
      console.error("Error sending OTP", error)
    } finally {
      LoadingService.setLoading(false)
    }
  }

  const handleConfirmOtp = async () => {
    if (confirmationResult) {
      LoadingService.setLoading(true)
      try {
        await confirmationResult.confirm(otp)
        // Handle successful login
        PopupService.setPopup(null)
      } catch (error) {
        console.error("Error verifying OTP", error)
      } finally {
        LoadingService.setLoading(false)
      }
    }
  }

  if (selectedRowData && user && !user?.isAnonymous) {
    return <Profile />
  }

  const signIn = async (provider, formData) => {
    if (provider.id === "google") {
      return await handleGoogleLogin()
    }

    if (provider.id === "apple") {
      return await handleAppleLogin()
    }

    if (provider.id === "credentials") {
      return await handleLogin(formData)
    }

    const promise = new Promise((resolve) => {
      setTimeout(() => {
        console.log(`Sign in with ${provider.id}`)
        resolve()
      }, 500)
    })
    return promise
  }

  // const Password = () => (
  //   <PasswordComponent value={password} onChange={(e) => setPassword(e.target.value)} />
  // )
  return (
    <AppProvider
      branding={{
        title: t("The Platform of Great Surprises"),
        subTitle: "",
        logo: <Logo />,
      }}
      theme={theme}
    >
      <SignInPage
        t={t}
        signIn={signIn}
        providers={providers}
        onGoogleSignIn={handleGoogleLogin} // Google Sign-In
        onAppleSignIn={handleAppleLogin} // Apple Sign-In
        onEmailSignIn={handleLogin} // Email/Password Sign-In
        // onPhoneSignIn={handlePhoneSignIn} // Phone Sign-In
        // slotProps={{
        //   passwordField: {
        //     component: Password,
        //   },
        // }}
      />

      <Container maxWidth="xs">
        {/* <CssBaseline /> */}
        <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
          {/* <Typography variant="h4" gutterBottom>
            {t("Login")}
          </Typography>
          <TextField
            label={t("email")}
            variant="outlined"
            margin="normal"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            label={t("passwordFieldPlaceholder")}
            variant="outlined"
            margin="normal"
            fullWidth
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!errors.password}
            helperText={errors.password}
          /> */}

          {mfaVerificationId && (
            <>
              <TextField
                label="OTP"
                variant="outlined"
                margin="normal"
                fullWidth
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleConfirmMfaOtp}
                sx={{ mt: 2 }}
              >
                {t("Confirm MFA OTP")}
              </Button>
            </>
          )}

          {/* <Button variant="outlined" color="primary" fullWidth onClick={handleLogin} sx={{ mt: 2 }}>
            {t("signIn")}
          </Button> */}

          {/* <div id="firebaseui-auth-container"></div> */}
          {/* <Button
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={handleGoogleLogin}
            sx={{ mt: 2 }}
          >
            {t("Sign In with Google")}
          </Button> */}
          {/* <Button
            variant="outlined"
            color="info"
            fullWidth
            onClick={handleAppleLogin}
            sx={{ mt: 2 }}
          >
            {t("Sign In with Apple")}
          </Button> */}

          <PhoneInput
            id="recaptcha-container"
            preferredCountries={["US", "DO"]}
            country={"us"}
            value={phoneNumber}
            onChange={(phone, option, e, format) => {
              // console.log("PhoneInput---->", { phone, option, e, format })
              setPhoneNumber(format)
            }}
            inputStyle={{ width: "100%" }}
            containerStyle={{ width: "100%", marginTop: 16 }}
          />

          <Button
            variant="outlined"
            color="success"
            fullWidth
            onClick={handlePhoneSignIn}
            sx={{ mt: 2 }}
          >
            {t("Sign In with Phone")}
          </Button>

          {confirmationResult && (
            <>
              <TextField
                label={t("OTP")}
                variant="outlined"
                margin="normal"
                fullWidth
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleConfirmOtp}
                sx={{ mt: 2 }}
              >
                {t("Confirm OTP")}
              </Button>
            </>
          )}
          <div id="recaptcha-container"></div>
          <Box display="flex" justifyContent="space-between" width="100%" mt={2}>
            <Button
              color="primary"
              onClick={() => {
                PopupService.pushPopup(<ForgotPasswordPage />)
              }}
            >
              {t("forgotPassword")}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                PopupService.setPopup(<RegistrationPage />)
              }}
            >
              {t("register")}
            </Button>
          </Box>
          {/* <Button color="primary" fullWidth onClick={() => {

                }} sx={{ mt: 2 }}>
                    Sign Up
                </Button> */}
        </Box>
      </Container>
    </AppProvider>
  )
}

export default LoginPage
