import { Box, CircularProgress, Container } from "@mui/material"
// src/Profile.tsx
import React, { useState } from "react"

import EditProfile from "./EditProfile"
import ViewProfile from "./ViewProfile"
import { auth } from "../firebase"
import { observer } from "mobx-react-lite"
import { useTranslation } from "react-i18next"
import { userStore } from "./stores/userStore"

const Profile: React.FC = observer(() => {
  const { t } = useTranslation()

  const [isEditing, setIsEditing] = useState(false)

  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleSave = () => {
    setIsEditing(false)
  }

  const handleCancel = () => {
    setIsEditing(false)
  }

  if (userStore.loading) {
    return (
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}
      >
        <CircularProgress />
      </Box>
    )
  }

  if (!auth.currentUser || auth.currentUser.isAnonymous) {
    return <div>{t("No user logged in")}</div>
  }

  return (
    <Container maxWidth="sm">
      {isEditing ? (
        <EditProfile onSave={handleSave} onCancel={handleCancel} />
      ) : (
        <ViewProfile onEdit={handleEdit} />
      )}
    </Container>
  )
})

export default Profile
